<template v-if="$isLoading">
  <div>
    <div class="position-relative">
      <b-container class="container-box bg-white px-4 pt-4">
        <b-row>
          <b-col md="12">
            <InputSelect
              class="mb-3"
              title="เหตุผลในการขอคืนสินค้า"
              name="Sortby"
              :options="option"
              v-model="form.reasonId"
              @onDataChange="handleChangeReason"
              valueField="id"
              textField="name"
              isRequired
              :isValidate="$v.form.reasonId.$error"
              :v="$v.form.reasonId"
            />
          </b-col>
          <b-col md="12">
            <InputTextArea
              class="mb-3"
              textFloat="รายละเอียดเพิ่มเติม"
              placeholder="กรอกรายละเอียดเพิ่มเติม"
              type="text"
              name="note"
              isRequired
              v-model="form.note"
              rows="7"
              :isValidate="$v.form.note.$error"
              :v="$v.form.note"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <UploadFile
              textFloat="ภาพประกอบ"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="image"
              name="thumbnail"
              :fileName="images"
              text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 4:3 ขนาดไม่เกิน 50 MB"
              isRequired
              v-on:onFileChange="onImageChange"
              :v="$v.images"
            />
          </b-col>
          <b-col md="12" class="d-flex">
            <div
              class="preview-box b-contain mt-2 mx-1"
              v-for="(data, index) in imgList"
              v-bind:key="index"
              v-bind:style="{
                'background-image': 'url(' + data.img + ')',
              }"
              @click="openModalImage(data)"
            >
              <font-awesome-icon
                v-if="data.img"
                icon="times-circle"
                class="text-secondary deletes-icon pointer"
                @click="deleteImage(index)"
              />
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingUpload[index].flag"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-container
        class="container-box bg-white px-4 mt-1"
        v-if="paymentType != 2"
      >
        <b-row>
          <b-col md="6">
            <InputSelect
              class="mb-3"
              title="ธนาคาร"
              name="Sortby"
              :options="bankLists"
              v-model="form.bankId"
              @onDataChange="handleChangeBank"
              valueField="id"
              textField="name"
              isRequired
              :isValidate="$v.form.bankId.$error"
              :v="$v.form.bankId"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <InputText
              textFloat="ชื่อบัญชีธนาคาร"
              placeholder="กรุณากรอกชื่อบัญชีธนาคาร"
              type="text"
              name="bankname"
              v-model="form.bankAccountName"
              isRequired
              :isValidate="$v.form.bankAccountName.$error"
              :v="$v.form.bankAccountName"
            />
          </b-col>
          <b-col md="6">
            <InputText
              textFloat="เลขบัญชีธนาคาร"
              placeholder="กรุณากรอกเลขบัญชีธนาคาร"
              type="number"
              name="bankno"
              v-model="form.bankAccountNo"
              isRequired
              :isValidate="$v.form.bankAccountNo.$error"
              :v="$v.form.bankAccountNo"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-container class="container-box bg-white px-2 py-4">
        <b-row class="px-3">
          <b-col class="d-flex justify-content-end align-items-center">
            <b-button class="button btn-cancel ml-2" @click="$emit('hide')"
              >ย้อนกลับ</b-button
            >
            <b-button class="btn-main ml-3" @click="checkForm()"
              >บันทึก</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Modal -->
    <!-- <ModalAlertConfirm
        msg="ยืนยันการลบ ?"
        :text="modalMessage"
        btnConfirm="ลบ"
        colorBtnConfirm="danger"
        btnCancel="ยกเลิก"
        ref="ModalAlertConfirm"
        @confirm="btnDelete"
      /> -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalImage ref="modalImage" :img="showImage" />
  </div>
</template>

<script>
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import UploadFile from "@/components/inputs/UploadFile";
import ModalImage from "@/components/modal/images/ModalImage";

import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "TierDetails",
  components: {
    InputText,
    UploadFile,
    ModalImage,
    ModalAlertError,
    ModalAlertConfirm,
    ModalLoading,
    InputTextArea,
    ModalAlert,
    InputSelect,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    paymentType: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      // orderId: this.$route.params.id,
      modalMessage: "",
      images: "",
      showImage: "",
      detail: "",
      isDisable: false,
      imgTypeId: 1,
      error: [],
      option: [],
      imgList: [
        {
          img: "",
          imgBase64: "",
        },
        {
          img: "",
          imgBase64: "",
        },
        {
          img: "",
          imgBase64: "",
        },
        {
          img: "",
          imgBase64: "",
        },
        {
          img: "",
          imgBase64: "",
        },
      ],
      isLoadingUpload: [
        {
          flag: false,
        },
        {
          flag: false,
        },
        {
          flag: false,
        },
        {
          flag: false,
        },
        {
          flag: false,
        },
      ],
      bankLists: [],
      form: {
        id: 0,
        orderId: this.$route.params.id,
        reasonId: null,
        bankAccountName: "",
        bankAccountNo: "",
        note: "",
        returnOrderDetailImage: [],
        bankId: null,
      },
    };
  },
  validations() {
    return {
      form: {
        reasonId: { required },
        note: { required },
        bankId: {
          required: requiredIf(function () {
            return this.paymentType != 2;
          }),
        },
        bankAccountNo: {
          required: requiredIf(function () {
            return this.paymentType != 2;
          }),
        },
        bankAccountName: {
          required: requiredIf(function () {
            return this.paymentType != 2;
          }),
        },
      },
      images: { required },
    };
  },
  created: async function () {
    this.$isLoading = false;
    await this.getData();
    this.$isLoading = true;
  },
  methods: {
    handleChangeReason: async function (value) {
      this.form.reasonId = value;
    },
    handleChangeBank: async function (value) {
      this.form.bankId = value;
    },
    openModalImage(val) {
      if (val.img != "") {
        this.images = val.img.split("//").pop().split("/")[2];
      } else return;
      // if (img && img.length > 0) {
      //   this.showImage = img;
      //   this.$refs.modalImage.show();
      // }
    },
    onImageChange(img) {
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        for (let index = 0; index < this.imgList.length; index++) {
          this.isLoadingUpload[index].flag = true;
          if (this.imgList[index].img == "") {
            this.images = await this.saveImagetoDb(reader.result);
            this.imgList[index].img = this.images;
            this.imgList[index].imgBase64 = reader.result;
            setTimeout(() => {
              this.isLoadingUpload[index].flag = false;
            }, 500);
            return;
          } else {
            this.isLoadingUpload[index].flag = false;
          }
        }
        this.isDisable = false;
      };
    },
    saveImagetoDb: async function (img) {
      var imgData = {
        base64: img,
        type: this.imgTypeId,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    deleteImage(index) {
      this.imgList.splice(index, 1);
      this.imgList.push({
        img: "",
        imgBase64: "",
      });

      this.images =
        this.imgList[0].img != ""
          ? this.imgList[0].img.split("//").pop().split("/")[2]
          : "";
    },
    getData: async function () {
      let list = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Transaction/ReturnOrder/ReturnReasons`,
        null,
        this.$headers,
        null
      );
      if (list.result == 1) {
        let val = [{ id: null, name: `กรุณาเลือกเหตุผล` }];
        this.option = val.concat(list.detail);
      }

      let banklists = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/bank/list`,
        null,
        this.$headers,
        null
      );
      if (banklists.result == 1) {
        let list = [{ id: null, name: `กรุณาเลือกธนาคาร` }];
        this.bankLists = list.concat(banklists.detail.dataList);
      }

      let res = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Transaction/ReturnOrder/Admin/${this.id}`,
        null,
        this.$headers,
        null
      );

      if (res.result == 1) {
        this.detail = res.detail.data;
        this.form.orderId = this.detail.id;
      }
    },
    checkForm: async function () {
      this.$v.form.$touch();
      this.$v.images.$touch();

      if (this.$v.form.$error || this.$v.images.$error) {
        this.$nextTick(() => {
          let target = document.querySelector(".text-error");
          if (target) {
            let domRect = document
              .querySelector(".text-error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 150
            );
          }
        });
        return;
      }

      this.$refs.modalLoading.show();
      this.form.returnOrderDetailImage = [];
      for (const e of this.imgList) {
        if (e.imgBase64 != "") {
          this.form.returnOrderDetailImage.push(e.imgBase64);
        }
      }
      this.form.bankId = this.form.bankId ? this.form.bankId : 0;

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/transaction/ReturnOrder/Admin/Create`,
        null,
        this.$headers,
        this.form
      );

      this.$refs.modalLoading.hide();
      if (resData.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        this.modalMessage = resData.message;
        this.$refs.modalAlertError.show();
        setTimeout(() => {
          this.$refs.modalAlertError.hide();
        }, 2000);
      }
    },
  },
};
</script>

<style scoped>
::v-deep .preview-box {
  width: 20%;
  padding-bottom: 20%;
  background-size: contain;
  cursor: pointer;
}

::v-deep .btn-download {
  display: none;
}

::v-deep .delete-icon {
  display: none;
}

.input-serach.error {
  border: 1px solid #e55353 !important;
}

.text-error {
  color: #ff0000;
  font-size: 14px;
}

.panel-input-serach {
  border: 1px solid #bcbcbc !important;
}

.search-modal {
  border: 2px solid #d0d0d0 !important;
}

.border-danger {
  border: 1px solid #e55353 !important;
}

.deletes-icon {
  position: absolute;
  right: 5%;
  top: 10px;
  cursor: pointer;
  z-index: 1000;
}

label {
  color: #16274a;
  font-size: 17px;
  margin-bottom: 2px;
  width: 100%;
  font-weight: bold;
}

::v-deep .square-boxs {
  margin: auto;
  width: 75%;
  padding-top: 60%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

::v-deep .modal-body .table-detail {
  min-height: 40rem;
}

.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  color: #16274a;
  margin-bottom: 0;
  font-size: 26px;
}

.img {
  width: 100px;
}

.modal-body {
  margin: auto;
  text-align: center;
}

@media (max-width: 820px) {
  ::v-deep .modal-dialog {
    max-width: 700px !important;
  }
}
</style>
